<template>
  <div class="logo">
    <img
      class="logo_img"
      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/202312261802.png"
      alt=""
    />
    <div></div>
    <div class="img_flex">
      <img
        class="left_img"
        src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/%E6%99%BA%E5%8F%82%E6%99%BA%E9%80%89%E8%BF%90%E8%90%A5%E6%80%BB%E5%90%8E%E5%8F%B0.png"
        alt=""
      />

      <el-form
        ref="ruleForm"
        :model="loginInfo"
        :rules="rules"
        :hide-required-asterisk="true"
        :show-message="false"
        label-width="80px"
        class="form"
      >
        <el-form-item label="" prop="mobile">
          <div class="bind_code">
            <el-input
              placeholder="请输入手机号"
              v-model="loginInfo.mobile"
              class="login-input"
            ></el-input>
            <img
              class="head_img"
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/20231226180201.png"
              alt=""
            />
          </div>
        </el-form-item>
        <el-form-item label="" prop="smsCode">
          <div class="bind_code" style="margin-top: 10px">
            <el-input
              type="text"
              placeholder="请输入验证码"
              v-model="loginInfo.smsCode"
              class="login-input"
              @keyup.enter.native="onSubmit"
            >
            </el-input>
            <el-button
              type="primary"
              plain
              @click.native.prevent="bindforgetSendCode"
              class="codebtn"
              :disabled="disabled"
            >
              {{ btnText }}
            </el-button>
            <img
              class="head_img"
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/20231226180202.png"
              alt=""
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="buttom" type="success" @click="onSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, getSms } from '@/api/login'
export default {
  data() {
    return {
      loginInfo: {},
      pwdType: 'password',
      eyeType: 'icon-jr-icon-eye',
      btnText: '获取验证码',
      disabled: false,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入手机号', trigger: 'blur' }
        ],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //发送验证码
    bindforgetSendCode() {
      this.$refs['ruleForm'].validateField('mobile', (errorMessage) => {
        if (!errorMessage) {
          setTimeout(() => {
            this.doloop(60)
          }, 500)
          getSms({ mobile: this.loginInfo.mobile }).then((res) => {
            // if (res.data.resultCode === 200) {
            //   this.disabled = true
            //   this.btnText = '请稍候...'

            //   this.$message.success(res.data.message)
            // } else {
            //   this.$message.error(res.data.message)
            // }
            this.disabled = true
            this.btnText = '请稍候...'
          })
        } else {
          this.$message.error('请输入正确的手机号！')
          return false
        }
      })
    },
    //验证码倒计时
    doloop(seconds) {
      seconds = seconds ? seconds : 60
      this.btnText = seconds + 's后获取'
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = '获取验证码'
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },

    //登录
    async onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          login(this.loginInfo).then((response) => {
            if (response.data.resultCode === 200) {
              const token = response.headers.token
              sessionStorage.setItem('token', token)
              sessionStorage.setItem('userId', response.data.data.id)
              sessionStorage.setItem('roleId', response.data.data.roleId)
              sessionStorage.setItem('userName', response.data.mobile)
              this.$message.success(response.data.message)
              this.$router.replace({
                path: '/'
              })
            } else {
              this.$message.error(response.data.message)
            }
          })
        } else {
          this.$message.error('请输入手机号跟验证码！')
        }
      })
    },
    changeType() {
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password'
      this.eyeType = this.pwdType === 'password' ? 'icon-jr-icon-eye' : 'icon-jr-icon-close'
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #448aff;
}
::v-deep .el-form-item__content {
  width: 300px;
  line-height: 40px;
  position: relative;
  font-size: 14px;
}
.logo {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
  position: relative;
  .bind_code {
    position: relative;
    width: 500px;
    .head_img {
      position: absolute;
      left: 6px;
      width: 32px;
      height: 32px;
      top: 10px;
    }
    .codebtn {
      position: absolute;
      right: 6px;
      font-size: 12px;
      height: 40px;
      line-height: 13px;
      top: 5px;
      width: 70px;
      border: 1px solid #ffffff;

      display: flex;
      justify-content: center;
    }
  }
  .img_flex {
    margin-top: 140px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .left_img {
      width: 500px;
      height: 63px;
      position: relative;
      top: -60px;
      left: 20px;
      z-index: 999;
    }
  }

  .logo_img {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .buttom {
    width: 500px;
    background: #4e93fb;
    border: 1px solid #4e93fb;
    height: 50px;
    border-radius: 4px;
    margin-top: 15px;
  }

  .form {
    position: relative;
    left: -120px;
    .login-input {
      width: 500px;
    }
  }
}
::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 50px;
  line-height: 40px;
  outline: 0;
  padding: 0 40px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>
